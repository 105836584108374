body {
  font-size: 14px;
}

.pr-pdf {
  .__prs-clientlogo {
    text-align: right !important;
  }
}

.pr-pdf-infobox {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em !important;

  .pr-pdf-infobox-date {
    flex: 1;
    padding: 10px 20px;
    border-right: 1px solid var(--boxBorderColor);
  }

  .pr-pdf-infobox-delivery {
    flex: 1;
    padding: 10px 20px;
    text-align: center;
    border-right: 1px solid var(--boxBorderColor);
  }

  .pr-pdf-infobox-scheduleddelivery {
    flex: 1;
    padding: 10px 20px;
    text-align: right;
  }
}

.pr-pdf {
  h1 {
    margin-top: 30px;
    font-size: 1.2rem;
  }

  .__box {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  ._prs-view-topline-logo {
    img {
      max-height: 40px;
    }
  }

  .__prs-clientaddress {
    font-size: 0.8rem;
  }

  .__pr-form-products {
    border-top: 1px solid var(--boxBorderColor);
    border-left: 1px solid var(--boxBorderColor);
    border-right: 1px solid var(--boxBorderColor);

    .__pr-form-product-head {
      font-size: 0.8em;
      border-bottom: 1px solid var(--boxBorderColor);
    }

    .__pr-form-product-body {
      font-size: 0.8em;
    }

    .__pr-form-product {
      border-bottom: var(--prFormListBodyColumnBorder);
    }

    .__pr-form-list-column {
      .product-id {
        font-size: 1em !important;
      }
    }
  }

  .__pr-form-pricetotal {
    .price-total {
      font-size: 0.7rem;
    }
  }

  footer {
    display: none;
  }

  .__cookiebanner {
    display: none;
  }
}
