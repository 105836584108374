.input-number {
  input {
    margin: 0;
    min-width: 50px;
    text-align: center;
    font-weight: bold;
  }

  .input-number-plus,
  .input-number-minus {
    display: block;
    width: 100%;
    padding: 5px 5px;
    line-height: initial;
    text-align: center;
    border: 1px solid rgb(220, 220, 220);
    color: var(--primaryColor);
    cursor: pointer;
  }

  .input-number-plus:hover,
  .input-number-minus:hover {
    background-color: var(--hoverColor);
  }

  .input-number-plus {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
  }

  .input-number-minus {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none;
  }
}

.view-only-buttons.input-number {
  .input-number-plus {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid rgb(220, 220, 220);
    margin-bottom: 5px;
  }

  .input-number-minus {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid rgb(220, 220, 220);
  }
}

.horizontal.input-number {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .input-number-plus,
  .input-number-minus {
    font-size: 0.7rem;
    width: auto;
  }

  .input-number-plus {
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
    border-left: 0;
  }

  .input-number-minus {
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 0px;
    border: 1px solid rgb(220, 220, 220);
    border-right: 0;
  }
}

.__pr-overview {
  .__adminlist-head {
    @media screen and (max-width: $breakSmall) {
      display: none;
    }
  }

  .__prs-pr-topline {
    text-align: center;
    border-bottom: 1px solid var(--boxBorderColor);
    padding: 4px 10px;
    font-weight: 600;
  }
}

.__prs-filter {
  input {
    @media screen and (max-width: $breakSmall) {
      height: 30px;
      font-size: 0.9em;
    }
  }
}

.__prs-view {
  .__admin-header-title {
    h1 {
      @media screen and (max-width: $breakSmall) {
        font-size: 1.6rem;
      }
    }
  }
  ._prs-view-topline {
    padding-bottom: 20px;

    button,
    a {
      margin-bottom: 5px;
    }
  }

  .__prs-view-bottomline {
    padding-top: 20px;
    text-align: right;
  }
}

.__prs-clientlogo {
  @media screen and (max-width: $breakSmall) {
    text-align: center;
    margin: 20px 0;
  }

  img {
    max-height: 60px;

    @media screen and (max-width: $breakSmall) {
      max-height: 50px;
    }
  }
}

.__prs-clientaddress {
  padding-top: 20px;
  line-height: 120%;
}

.__pro-form-search {
  input,
  select,
  button {
    margin-bottom: 0;
  }
}

.__pr-form-products {
  //border: var(--prFormListBodyColumnBorder);

  .__pr-form-product-head {
    padding: 6px 10px;
    font-weight: 600;
    background: var(--prFormListHeadBackground);

    @media screen and (max-width: $breakSmall) {
      display: none;
    }
  }

  .__pr-form-product-body {
    .__box {
      margin-bottom: 5px;
    }

    .__pr-form-product-body-number {
      display: none;
      text-align: center;
      padding-top: 10px;
      color: var(--primaryColor);
      font-weight: 600;
      font-size: 1rem;

      @media screen and (max-width: $breakSmall) {
        display: block;
      }
    }

    .__pr-form-product {
      .__pr-form-list-column {
        height: 100%;
        padding: 5px 10px;
        border-right: var(--prFormListBodyColumnBorder);

        .product-id {
          // color: rgb(150, 150, 150);
          color: var(--primaryColor);
          font-weight: 700;
          font-size: 0.75rem;
          font-family: monospace;
        }

        .product-name {
          margin-top: -3px;
          font-weight: 600;

          button {
            color: var(--primaryColor);
            text-decoration: underline;
            cursor: pointer;
            font-weight: 600;

            .fa {
              font-size: 0.9em;
            }
          }

          button:hover {
            text-decoration: none;
          }

          span {
            color: var(--primaryColor);
            font-weight: 600;
          }
        }

        .product-description {
          margin-top: -3px;
        }
      }

      .__pr-form-list-column.type-input {
        input,
        select {
          margin-bottom: 0;
        }
      }

      .__pr-form-list-column.type-price {
        text-align: right;
        font-family: monospace;
        font-size: 0.9em;

        @media screen and (max-width: $breakSmall) {
          font-size: 0.82em;
        }
      }

      .__pr-form-list-column.type-unit {
        @media screen and (max-width: $breakSmall) {
          line-height: 36px;
        }
      }
    }

    // ul li:nth-of-type(odd) .__pr-form-product {
    //   background-color: var(--prFormListRowBackgroundOdd);
    // }

    // ul li:nth-of-type(even) .__pr-form-product {
    //   background-color: var(--prFormListRowBackgroundEven);
    // }
  }
}

.__pr-form-pricetotal {
  padding-bottom: 20px;

  .price-total {
    border-top: 1px solid rgb(180, 180, 180);
    padding: 10px 10px;
    text-align: right;
    font-family: monospace;
    font-weight: 600;
    font-size: 0.8rem;
  }
}

.__pr-form-options {
  input,
  select {
    margin-bottom: 0;
  }
}

.__pr-status {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.__pr-status-status-container {
  flex: 1;
  text-align: center;

  .__pr-status-status-bubble {
    width: 45px;
    height: 45px;
    margin: 0 auto;

    background: rgb(220, 220, 220);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;

    @media screen and (max-width: $breakLarge) {
      width: 40px;
      height: 40px;
    }

    .fa {
      font-size: 1.1rem;
    }

    .fa-check {
      font-size: 1.2rem;
    }

    .fa-times {
      font-size: 1.3rem;
    }
  }

  .__pr-status-status-label {
    padding: 5px 0;

    @media screen and (max-width: $breakSmall) {
      display: none;
    }

    .fa {
      display: none;
    }
  }
}

.done.__pr-status-status-container {
  .__pr-status-status-bubble {
    background-color: var(--statusLightGreen);
    color: var(--statusGreen);
  }

  .__pr-status-status-label {
    color: var(--statusGreen);
    font-weight: 600;

    .fa {
      display: inline-block;
    }
  }
}

.done.__pr-status-status-container.status-CANCELED {
  .__pr-status-status-bubble {
    background-color: var(--statusLightRed);
    color: var(--statusRed);
  }

  .__pr-status-status-label {
    color: var(--statusRed);
    font-weight: 600;

    .fa {
      display: inline-block;
    }
  }
}

.__pr-status-location-overview {
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 0;

  .__pr-status-status-container {
    flex: auto;
  }

  .__pr-status-status-bubble {
    width: 35px;
    height: 35px;
    margin: 0;

    @media screen and (max-width: $breakLarge) {
      width: 30px;
      height: 30px;

      .fa {
        font-size: 1rem;
      }
    }
  }

  .__pr-status-status-label {
    display: none;
  }
}

.__pr-freeitems {
  // border-top: 1px solid var(--boxBorderColor);
  // padding-top: 10px;

  .__pr-freeitems-add {
    text-align: center;
    margin-bottom: 20px;

    @media screen and (max-width: $breakSmall) {
      padding: 20px 0;
    }
  }
}

.pr-list-priceTotal {
  font-weight: 600;
  font-size: 0.9rem;

  @media screen and (max-width: $breakSmall) {
    font-size: 1rem;
    color: green;
  }
}

.pr-list-createdAt {
  @media screen and (max-width: $breakSmall) {
    font-weight: 600;
  }
}

.pr-list-identifier {
  @media screen and (max-width: $breakSmall) {
    font-weight: 600;
    text-align: right;
  }
}
