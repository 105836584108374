.admin-clients-list-logo {
  text-align: center;

  img {
    max-height: 40px;
    width: auto;
  }
}

.admin-clients-popup-view-adduser {
  .admin-clients-popup-view-adduser-email {
    display: flex;
    justify-content: space-between;
    gap: 20px;

    input,
    button {
      margin-bottom: 0;
    }

    button {
      min-width: 180px;
    }
  }
}

.admin-clients-popup-view-adduser-foundaccount {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .admin-clients-popup-view-adduser-foundaccount-content {
    flex-grow: 1;

    .admin-clients-popup-view-adduser-foundaccount-content-name {
      font-weight: 700;

      .label {
        font-weight: 600;
        margin-left: 5px;
      }
    }

    .admin-clients-popup-view-adduser-foundaccount-content-buttons {
      button {
        color: var(--primaryColor);
        cursor: pointer;
        font-size: 0.8em;
        margin-right: 10px;
      }

      button:hover {
        text-decoration: underline;
      }

      button.button-remove {
        color: red;
      }
    }
  }
}

.admin-clients-popup-products {
  .admin-clients-popup-products-categories {
    li.admin-clients-popup-products-category {
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .admin-clients-popup-products-button-select {
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: left;
        background-color: rbg(230, 230, 230);
        border: 2px solid rgb(220, 220, 220);
        padding: 6px 10px;
        margin: 3px 0;
        border-radius: 3px;

        display: flex;
        justify-content: space-between;
        gap: 4px;

        .product-category-name {
          flex-grow: 1;
          font-weight: 600;
          word-break: break-word;
          overflow-wrap: break-word;
        }

        .product-category-productscount {
          text-align: right;
        }
      }

      .admin-clients-popup-products-button-select:hover {
        border-color: var(--primaryColor) !important;
        background-color: white !important;
      }

      .admin-clients-popup-products-button-settings {
        width: 20px;
        padding: 6px 0;
        text-align: right;

        button {
          cursor: pointer;
        }

        button:hover {
          color: var(--primaryColor);
        }
      }
    }

    li.admin-clients-popup-products-category.not-selected {
      .admin-clients-popup-products-button-select {
        .icon-not-selected {
          display: inline-block;
        }

        .icon-selected {
          display: none;
        }

        .product-category-productscount {
          display: none;
        }
      }
    }

    li.admin-clients-popup-products-category.selected {
      .admin-clients-popup-products-button-select {
        border-color: var(--statusGreen);
        background-color: var(--statusShallowGreen);

        .icon-not-selected {
          display: none;
        }

        .icon-selected {
          display: inline-block;
          color: var(--statusGreen);
        }
      }
    }
  }
}

.admin-clients-popup-products-copyfrom {
  label {
    line-height: 40px;
  }

  select {
    margin: 0;
  }
}
