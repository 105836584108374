.pr-mobile-form-product {
  margin-bottom: 20px;

  .pr-mobile-form-product-number {
    text-align: center;
    color: var(--primaryColor);
    font-weight: 700;
  }

  .pr-mobile-form-product-box {
    border: 1px solid var(--boxBorderColor);
    background-color: var(--boxBackground);
    -webkit-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
    padding: 10px;

    .mobile-product-box-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .mobile-product-row-name {
      flex-grow: 1;
    }

    .mobile-product-amount {
      padding-right: 20px;

      .is-not-editmode {
        text-align: right;
        color: var(--primaryColor);
        font-size: 1.2rem;
      }
    }
    .mobile-product-name {
      .mobile-product-name-id {
        font-family: "Courier New", Courier, monospace;
        font-weight: 600;
        font-size: 0.8rem;
        color: var(--primaryColor);
      }

      .mobile-product-name-name {
        .mobile-product-name-name-name {
          font-weight: 700;
        }

        .mobile-product-name-name-unit {
          font-family: "Courier New", Courier, monospace;
          font-size: 0.9em;
          padding-left: 5px;
        }
      }
    }

    .mobile-product-row-price {
      padding-top: 5px;

      .mobile-product-price-unit {
        padding-right: 5px;
        font-family: "Courier New", Courier, monospace;
        font-size: 0.9em;
      }

      .mobile-product-price-total {
        font-weight: 700;
        font-family: "Courier New", Courier, monospace;
        font-size: 0.9em;
      }
    }
  }
}
