.__adminlist-head {
  font-weight: 600;
  background-color: var(--adminlistHeadBackground);
  color: var(--adminlistHeadColor);
  padding-left: var(--adminlistHeadPaddingH);
  padding-right: var(--adminlistHeadPaddingH);

  .__adminlist-cell {
    padding-top: var(--adminlistHeadPaddingV);
    padding-bottom: var(--adminlistHeadPaddingV);
  }
}

.__adminlist-loading {
  text-align: center;
  padding: 30px 0;

  .fa {
    font-size: 1.6rem;
  }
}

.__adminlist-row:nth-of-type(odd) {
  background: var(--adminlistRowBackgroundOdd);
}

.__adminlist-row:nth-of-type(even) {
  background: var(--adminlistRowBackgroundEven);
}

.__adminlist-row {
  padding-left: var(--adminlistRowPaddingH);
  padding-right: var(--adminlistRowPaddingH);

  .__adminlist-cell {
    padding-top: var(--adminlistRowPaddingV);
    padding-bottom: var(--adminlistRowPaddingV);
  }

  .selected-icon {
    display: none;
    color: var(--statusGreen);
    padding-right: 5px;
  }

  .unselected-icon {
    display: none;
    color: var(--statusRed);
    padding-right: 5px;
  }
}

.selected.__adminlist-row {
  .selected-icon {
    display: inline-block;
  }
}

.unselected.__adminlist-row {
  opacity: 0.3;

  .unselected-icon {
    display: inline-block;
  }
}

.border-left-solid,
.border-left-dashed,
.border-left-dotted {
  border-left-width: 1px;
}

.border-right-solid,
.border-right-dashed,
.border-right-dotted {
  border-right-width: 1px;
}

.border-left-solid {
  border-left-style: solid;
}

.border-right-solid {
  border-right-style: solid;
}

.border-left-dashed {
  border-left-style: dashed;
}

.border-right-dashed {
  border-right-style: dashed;
}

.border-left-dotted {
  border-left-style: dotted;
}

.border-right-dotted {
  border-right-style: dotted;
}

.__adminlist-head {
  .border-left-solid,
  .border-left-dashed,
  .border-left-dotted,
  .border-right-solid,
  .border-right-dashed,
  .border-right-dotted {
    border-color: var(--adminListHeadBorderColor);
  }
}

.__adminlist-body {
  .border-left-solid,
  .border-left-dashed,
  .border-left-dotted,
  .border-right-solid,
  .border-right-dashed,
  .border-right-dotted {
    border-color: var(--adminListRowBorderColor);
  }
}

.__adminlist-clickable-row {
  display: block;
  width: 100%;
  text-align: left;
  line-height: initial;
  cursor: pointer;

  .__adminlist-cell {
    padding-top: var(--adminlistRowPaddingV);
    padding-bottom: var(--adminlistRowPaddingV);
  }
}

.__adminlist-clickable-row:hover {
  background-color: var(--hoverColor);
}

.__adminlist-nonclickable-row {
  display: block;
  width: 100%;
  text-align: left;
  line-height: initial;
}

.__adminlist-avatar {
  margin-right: 10px;
}

.__adminlist-name {
  .__adminlist-name-title {
    font-weight: 400;
    color: var(--headingColor);
  }

  .__adminlist-name-subtitle {
    font-size: 0.8rem;
    color: var(--grayColor);
  }
}

.__adminlist-subtitle {
  font-size: 0.8rem;
  color: var(--grayColor);
}

.__adminlist-body-pagination {
  padding: var(--paginationPadding 0);

  button.__adminlist-body-pagination-page {
    display: inline-block;
    text-align: center;
    height: var(--paginationPageHeight);
    min-width: var(--paginationPageHeight);
    margin-right: var(--paginationPageMargin);
    line-height: var(--paginationPageHeight);
    border: var(--paginationPageBorder);
    background: var(--paginationPageBackground);
    color: var(--paginationPageColor);
    cursor: pointer;
  }

  button.__adminlist-body-pagination-page.selected {
    border: var(--paginationSelectedPageBorder);
    background: var(--paginationSelectedPageBackground);
    color: var(--paginationSelectedPageColor);
  }

  button.__adminlist-body-pagination-page.disabled {
    opacity: 0.1;
    cursor: default;
  }

  button.__adminlist-body-pagination-page:hover {
    border: var(--paginationSelectedPageBorder);
  }

  button.__adminlist-body-pagination-page.disabled:hover {
    border: var(--paginationSelectedPageBorder);
  }
}

.__adminlist-sortline {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.__adminlist-sortline-left {
  flex-grow: 1;

  input {
    @media screen and (max-width: $breakSmall) {
      padding-top: 2px;
      padding-bottom: 2px;
      height: auto;
    }
  }
}

.__adminlist-selectperpage {
  display: inline-block;
  text-align: right;
  margin-left: 5px;

  select {
    width: 120px;

    @media screen and (max-width: $breakSmall) {
      padding-top: 2px;
      padding-bottom: 2px;
      height: auto;
      width: 90px;
    }
  }
}

.__adminlist-sortby {
  display: inline-block;
  text-align: right;
}
