.products-categories-list {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .products-categories-list-categories {
    width: 240px;

    ul {
      background: var(--boxBackground);
      border: 1px solid var(--boxBorderColor);

      li {
        button {
          display: block;
          width: 100%;
          text-align: left;
          cursor: pointer;
          padding: 10px 10px;
          border-bottom: 1px solid var(--boxBorderColor);
        }

        button:hover {
          background-color: var(--hoverColor);
        }

        button.selected {
          background-color: var(--hoverColor);
        }
      }

      li.categories-title {
        background: var(--primaryColor);
        padding: 10px 10px;
        text-align: center;
        font-weight: 600;
      }
    }
  }

  .products-categories-list-products {
    flex-grow: 1;

    h2 {
      color: var(--headingColor);
      text-align: center;
    }
  }
}

.admin-products-search {
  border-top: 1px solid var(--boxBorderColor);
  border-bottom: 1px solid var(--boxBorderColor);
  padding: 10px 20px 8px 20px;
  margin: 0;
}

.__products-csv-import {
  .view-list {
    .list-head {
      font-weight: bold;
      border-bottom: 1px solid var(--boxBorderColor);
    }

    .list-body-entry {
      padding: 5px 5px;

      select {
        margin-bottom: 0;
      }
    }
  }

  .view-finished {
    a.primary.button {
      margin-top: 20px;

      .fa {
        color: var(--primaryColor);
      }
    }
  }
}
