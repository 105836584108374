.__admin-prs-changestatus-currentstatus {
  text-align: center;
  padding: 20px 0;

  .__admin-prs-changestatus-currentstatus-title {
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
}

.__admin-prs-changestatus-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: $breakSmall) {
    display: block;
  }

  .__admin-prs-changestatus-buttons-prevbutton {
    flex: 1;

    button {
      width: 100%;
    }
  }

  .__admin-prs-changestatus-buttons-nextbutton {
    flex: 1;

    button {
      width: 100%;
    }
  }
}

._prs-admin-pr-buttons {
  display: flex;
  justify-content: space-between;
}
