@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import Foundation Core */
@import "./foundation/foundation";

@import "../../node_modules/css.gg/icons/icons.scss";

/* Import default variables */
@import "variables/variables";

/* Overrides for default Foundation styles */
@import "./scss/core//foundation.overrides.scss";

/* Import Fonts */
//@import "./scss/fonts/lato";
@import "./scss/fonts/sourcesanspro";

/* Import Core SCSS Files */
@import "./scss/core/main";
@import "./scss/core/topbar";
@import "./scss/core/usernotifications";
@import "./scss/core/box";
@import "./scss/core/forms";
@import "./scss/core/formerrors";

/* Import administration styles */
@import "./scss/admin/admin";
@import "./scss/admin/adminlist";
@import "./scss/admin/accounts";
@import "./scss/admin/userroles";
@import "./scss/admin/emailtemplates";

@import "./scss/admin/products";
@import "./scss/admin/clients";
@import "./scss/admin/pr";

/*
 * Register all Components SCSS files here
 */
@import "./scss/components/popup";
@import "./scss/components/dialog";
@import "./scss/components/saving";
@import "./scss/components/imageupload";
@import "./scss/components/statusindicator";
@import "./scss/components/calloutbox";
@import "./scss/components/dropdownmenu";
@import "./scss/components/cookiebanner";
@import "./scss/components/languageswitch";
@import "./scss/components/switch";

@import "../components/main/errors/stylesheets/error.fatalerror";

/* Import SCSS files for pages */
@import "./scss/pages/login";
@import "./scss/pages/myaccount";
@import "./scss/pages/index";

@import "./scss/prs/pr.scss";
@import "./scss/prs/prmobile.scss";
@import "./scss/prs/prpdf.scss";
