.index-welcome {
  padding: 100px 0;
  text-align: center;
  color: var(--primaryColor);
  font-size: 1.6rem;

  @media screen and (max-width: $breakSmall) {
    display: none;
    font-size: 1.2rem;
    padding: 20px 0 40px 0;
  }
}
.__index-pr-overview {
  .__prs-clientlogo {
    @media screen and (max-width: $breakSmall) {
      margin-bottom: 5px;
    }
  }

  .__admin-header-title {
    h1 {
      @media screen and (max-width: $breakSmall) {
        font-size: 1.6rem;
      }
    }
  }
}
