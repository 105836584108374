.__topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--topbarHeight);
  border-bottom: var(--topbarBorder);
  background: var(--topbarBackground);
  z-index: 10000;

  .__topbar-logo {
    text-align: center;
    //padding-left: var(--topbarPadding);
    padding-top: var(--topbarPadding);

    img {
      //display: block;
      margin: 0;
      height: calc(var(--topbarHeight) - var(--topbarPadding) - var(--topbarPadding));
    }
  }

  .__topbar-right-side {
    text-align: right;
  }

  .__topbar-notifications-button-wrapper {
    float: right;

    .__topbar-notifications-button {
      display: inline-block;
      height: var(--topbarHeight);
      line-height: var(--topbarHeight);
      padding-left: var(--dropdownUserButtonPadding);
      padding-right: var(--dropdownUserButtonPadding);
      cursor: pointer;

      .fa {
        font-size: 1.2rem;
      }

      * {
        cursor: pointer;
      }
    }

    .__topbar-notifications-button:hover {
      background: var(--hoverColor);
    }
  }

  .__topbar-notifications-button-content-wrapper {
    position: relative;
  }

  .__topbar-login-button {
    display: inline-block;
    height: var(--topbarHeight);
    line-height: var(--topbarHeight);
    padding-left: var(--dropdownUserButtonPadding);
    padding-right: var(--dropdownUserButtonPadding);
    cursor: pointer;
  }

  .__topbar-login-button:hover {
    background: var(--hoverColor);
  }

  .__topbar-dropdown-wrapper {
    float: right;

    .__topbar-dropdown-modal {
      background: var(--dropdownMenuModal);
      position: fixed;
      top: var(--topbarHeight);
      left: 0;
      width: 100vw;
      height: calc(100vh - var(--topbarHeight));
    }

    .__topbar-dropdown-button {
      display: inline-block;
      height: var(--topbarHeight);
      line-height: var(--topbarHeight);
      padding-left: var(--dropdownUserButtonPadding);
      padding-right: var(--dropdownUserButtonPadding);
      cursor: pointer;

      .__avatar {
        cursor: inherit;
      }

      .__avatar-with-image {
        margin-top: 8px;
      }

      .__topbar-dropdown-button-name {
        font-weight: 500;
        color: var(--primaryColor);
      }
    }

    .__topbar-dropdown-button:hover {
      background: var(--hoverColor);
    }

    .__topbar-dropdown-menu {
      position: fixed;
      top: var(--topbarHeight - 8px);
      right: 0;
      border: var(--dropdownMenuBorder);
      background: var(--dropdownMenuBackground);

      ul {
        li {
          button,
          a {
            display: block;
            width: 100%;
            padding: var(--dropdownMenuPadding);
            text-align: left;
            background: var(--dropdownMenuItemBackground);
            color: var(--dropdownMenuItemColor);
            cursor: pointer;
            line-height: inherit;
          }

          button:hover,
          a:hover {
            background: var(--dropdownMenuItemHoverBackground);
            color: var(--dropdownMenuItemHoverColor);
          }
        }
      }
    }
  }
}

.__topbar-badge {
  position: absolute;
  display: inline-block;
  background-color: var(--topbarBadgeBackground);
  color: var(--topbarBadgeColor);
  width: var(--topbarBadgeSize);
  height: var(--topbarBadgeSize);
  line-height: var(--topbarBadgeSize);
  border-radius: 50%;
  font-size: 0.6rem;
  font-weight: 800;
  bottom: 12px;
  right: -7px;
}
