$breakSmall: 860px;
$breakMedium: 1024px;
$breakLarge: 1200px;
$breakXLarge: 1440px;

/* MAIN COLORS */
:root {
  --tw-color-primary: 1 195 198;
  --tw-color-hover: 180 254 255;

  --primaryColor: #01c3c6;
  --secondaryColor: #003d5b;

  --hoverColor: #b4feff;

  /* BACKGROUND COLORS */
  --bodyBackground: rgb(251, 251, 251);

  /* TEXT COLORS */
  --textColor: #444;
  --grayColor: #666;
  --lightGrayColor: #999;

  --missingLabelColor: red;
  --missingInputBackground: rgb(244, 211, 211);
  --warningInputBackground: rgb(252, 213, 174);
  --successInputBackground: rgb(196, 243, 196);

  --headingColor: var(--primaryColor);

  /* FONTS */
  --bodyFontSize: 15px;
  --headingFontFamily: "Source Sans Pro", Helvetica, Arial, sans-serif;
  --primaryFontFamily: "Source Sans Pro", Helvetica, Arial, sans-serif;

  /* MAIN PAGE CONTENT */
  --padding: 20px;

  /* BREAK POINTS */
  --breakSmall: 860px;
  --breakMedium: 1024px;
  --breakLarge: 1200px;
  --breakXLarge: 1440px;

  /* ERRORS */
  --errorFatalErrorModalBackground: rgba(255, 255, 255, 0.9);
  --errorFatalErrorPopupBackground: rgb(255, 218, 218);
  --errorFatalErrorPopupColor: black;

  /* STATUS INDICATORS */
  --statusGreen: rgb(84, 198, 84);
  --statusOrange: rgb(235, 163, 30);
  --statusRed: rgb(238, 30, 30);

  --onlineStatusONLINE: rgb(84, 198, 84);
  --onlineStatusAWAY: rgb(235, 163, 30);
  --onlineStatusOFFLINE: rgb(238, 30, 30);

  --statusLightGreen: rgb(196, 243, 196);
  --statusLightOrange: rgb(245, 227, 193);
  --statusLightRed: rgb(245, 165, 165);

  --statusShallowGreen: rgb(230, 245, 230);
  --statusShallowOrange: rgb(246, 238, 224);
  --statusShallowRed: rgb(246, 211, 211);

  /* DEFAULT BOX */
  --boxBorderColor: rgb(235, 235, 235);
  --boxBorder: 1px solid var(--boxBorderColor);
  --boxBackground: white;
  --boxBorderRadius: 5px;
  --boxPadding: var(--padding);
  --boxSmallPadding: calc(var(--boxPadding) / 2);
  --boxMargin: var(--padding);
  --boxSmallMargin: var(--boxMargin);

  /* COOKIE BANNER */
  --cookiebannerSmallBackground: white;
  --cookiebannerSmallBorder: 1px solid rgb(200, 200, 200);
  --cookiebannerSmallBorderRadius: 10px;
  --cookiebannerSmallColor: rgb(100, 100, 100);
  --cookiebannerSmallPadding: 5px 15px;

  --cookiebannerMediumWidth: 600px;
  --cookiebannerMediumBackground: #e8ffff;
  --cookiebannerMediumBorder: 1px solid rgb(200, 200, 200);
  --cookiebannerMediumBorderRadius: 10px;
  --cookiebannerMediumColor: rgb(100, 100, 100);
  --cookiebannerMediumPadding: 8px 15px;

  /* TOPBAR */
  --topbarHeight: 60px;
  --topbarBorder: 1px solid rgb(220, 220, 220);
  --topbarBackground: white;
  --topbarPadding: 18px;
  --topbarBadgeBackground: #e15554;
  --topbarBadgeColor: white;
  --topbarBadgeSize: 16px;

  --dropdownMenuModal: rgba(255, 255, 255, 0.5);
  --dropdownUserButtonPadding: 10px;
  --dropdownMenuBorder: 1px solid rgb(220, 220, 220);
  --dropdownMenuBackground: white;
  --dropdownMenuPadding: 10px 20px;
  --dropdownMenuItemBackground: transparent;
  --dropdownMenuItemColor: var(--primaryColor);
  --dropdownMenuItemHoverBackground: var(--hoverColor);
  --dropdownMenuItemHoverColor: var(--primaryColor);

  --userNotificationsMenuWidth: 360px;
  --userNotificationsMenuModal: rgba(255, 255, 255, 0.5);
  --userNotificationsMenuBorder: 1px solid rgb(220, 220, 220);
  --userNotificationsMenuBackground: white;
  --userNotificationsMenuPadding: 15px 0 0 0;
  --userNotificationsMenuItemUnreadBackground: rgb(253, 239, 239);
  --userNotificationsMenuItemBackground: transparent;
  --userNotificationsMenuItemColor: var(--primaryColor);
  --userNotificationsMenuItemHoverBackground: var(--hoverColor);
  --userNotificationsMenuItemHoverColor: var(--primaryColor);

  /* TABS */
  --tabsPadding: 8px 18px;
  --tabsBorder: 1px solid rgb(200, 200, 200);
  --tabsBorderRadius: 5px;
  --tabsBackground: transparent;
  --tabsColor: #444;
  --tabsSelectedBorder: 1px solid --primaryColor;
  --tabsSelectedBackground: var(--primaryColor);
  --tabsSelectedColor: white;
  --tabsHoverBackground: rgb(230, 230, 230);
  --tabsHoverColor: #444;
  --tabsBadgeBackground: rgb(200, 200, 200);
  --tabsBadgeColor: black;
  // --tabsPadding: 8px 18px;
  // --tabsBorder: 1px solid rgb(200, 200, 200);
  // --tabsBorderRadius: 5px;
  // --tabsBackground: transparent;
  // --tabsColor: #444;
  // --tabsSelectedBorder: 1px solid --primaryColor;
  // --tabsSelectedBackground: var(--primaryColor);
  // --tabsSelectedColor: white;
  // --tabsHoverBackground: rgb(230, 230, 230);
  // --tabsHoverColor: #444;
  // --tabsBadgeBackground: var(--primaryColor);
  // --tabsBadgeColor: white;

  /* POPUPS */
  --popupModalBackground: rgba(0, 0, 0, 0.8);
  --popupBorder: var(--boxBorder);
  --popupBackground: var(--boxBackground);
  --popupPadding: var(--padding);

  /* DIALOG */
  --dialogModalBackground: rgba(0, 0, 0, 0.8);
  --dialogBorder: var(--boxBorder);
  --dialogBorderRadius: 5px;
  --dialogBackground: var(--boxBackground);
  --dialogPadding: var(--padding);
  --dialogWidth: 600px;

  /* SAVING */
  --savingModalBackground: rgba(255, 255, 255, 0.9);
  --savingBorder: var(--boxBorder);
  --savingBorderRadius: 5px;
  --savingBackground: var(--boxBackground);
  --savingPadding: 60px var(--padding);
  --savingWidth: 600px;

  /* IMAGE UPLOAD */
  --dropareaPadding: 60px 40px;
  --dropareaBorder: 1px dashed rgb(190, 190, 190);
  --dropareaBackground: rgb(245, 245, 245);
  --dropareaHoverBorder: 1px dashed --primaryColor;
  --dropareaHoverBackground: var(--hoverColor);

  /* ADMINISTRATION */
  --adminNavWidth: 220px;
  --adminNavBorder: 1px solid rgb(200, 200, 200);
  --adminNavItemPadding: 5px 20px;
  --adminNavItemBackground: transparent;
  --adminNavItemColor: var(--primaryColor);
  --adminNavItemHoverBackground: var(--primaryColor);
  --adminNavItemHoverColor: white;
  --adminNavItemHSelectedBackground: var(--adminNavItemHoverBackground);
  --adminNavItemHSelectedColor: var(--adminNavItemHoverColor);

  /* ADMIN LIST */
  --adminlistHeadBackground: rgb(230, 230, 230);
  --adminlistHeadColor: #333;
  --adminlistHeadPaddingV: 10px;
  --adminlistHeadPaddingH: 10px;
  --adminlistRowPaddingV: 10px;
  --adminlistRowPaddingH: 10px;
  --adminlistRowSmallPaddingV: 10px;
  --adminlistRowSmallPaddingH: 10px;
  --adminlistRowBackgroundOdd: var(--bodyBackground);
  --adminlistRowBackgroundEven: rgb(244, 244, 244);
  --adminListHeadBorderColor: rgb(210, 210, 210);
  --adminListRowBorderColor: rgb(225, 225, 225);

  --paginationPadding: 10px;
  --paginationPageHeight: 24px;
  --paginationPageMargin: 5px;
  --paginationPageBorder: 1px solid --boxBorderColor;
  --paginationPageBackground: transparent;
  --paginationPageColor: rgb(150, 150, 150);
  --paginationSelectedPageBorder: 1px solid --primaryColor;
  --paginationSelectedPageBackground: var(--hoverColor);
  --paginationSelectedPageColor: var(--primaryColor);

  /* DROPDOWN MENU BUTTON */
  --dropdownbuttonMenuWidth: 290px;
  --dropdownbuttonItemPadding: 14px;
  --dropdownbuttonMenuItemBackground: transparent;
  --dropdownbuttonMenuItemColor: var(--primaryColor);
  --dropdownbuttonMenuItemHoverBackground: var(--hoverColor);
  --dropdownbuttonMenuItemHoverColor: var(--primaryColor);
  --dropdownbuttonMenuItemBorder: 1px solid --boxBorderColor;

  /* FOOTER */
  --footerPadding: 40px;
  --footerBackground: #605f5f;
  --footerColor: white;
  --footerFontSize: 0.9rem;

  /* INPUT */
  --inputLabelBorder: 1px solid var(--lightGrayColor);
  --inputEditBackground: #fffef5;
  --inputPadding: 8px 12px;
  --inputBorder: 1px solid rgb(230, 230, 230);
  --inputBorderRadius: 0;
  --inputMissingLabelColor: red;
  --inputMissingBackgroundColor: #ffe5df;

  /* LOGIN */
  --loginBoxPadding: 20px;
  --loginBoxBorder: var(--boxBorder);
  --loginBoxBackground: var(--boxBackground);

  /* PURCHASE REQUISITIONS */
  --prFormListHeadBackground: rgb(230, 230, 230);
  --prFormListHeadColumnBorder: 1px solid rgb(230, 230, 230);
  --prFormListBodyColumnBorder: 1px solid rgb(230, 230, 230);
  --prFormListRowBackgroundOdd: var(--boxBackground);
  --prFormListRowBackgroundEven: rgb(250, 250, 250);
}
